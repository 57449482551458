import axios from "axios";

const API_URL = "https://api-raven.k4anubhav.com/";

class AuthService {
    login(username: string, password: string) {
        return axios
            .post(API_URL + "token/", {
                username,
                password
            })
            .then(response => {
                localStorage.setItem("token", response.data.access);
                return response.data;
            });
    }

    logout() {
        localStorage.removeItem("token");
    }

    getCurrentToken() {
        const token = localStorage.getItem("token");
        if (token) return token;

        return null;
    }
}

export default new AuthService();
