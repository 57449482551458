import {Component} from "react";
import {Navigate} from "react-router-dom";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import INote from "../types/note.type";

type Props = {};

type State = {
    redirect: string | null,
    ready: boolean,
    notes: INote[],
    warning: string | null
}
export default class Notes extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            redirect: null,
            ready: false,
            notes: [],
            warning: null
        };
    }

    componentDidMount() {
        const token = AuthService.getCurrentToken();

        if (!token) this.setState({redirect: "/"});
        UserService.getNotes().then(
            notes => {
                this.setState({
                    notes: notes,
                    ready: true,
                    warning: null
                });
            },
            error => {
                console.log(error.response);
                this.setState({
                    notes: [],
                    ready: true,
                    warning: error.message
                });
            }
        );
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect}/>
        }

        const {notes, warning} = this.state;

        return (
            <div className="container">
                {(this.state.ready) ?
                    <div>
                        {(warning) ?
                            <div className="alert alert-warning" role="alert">
                                {warning}
                            </div> : null
                        }
                        <div className="row">
                            {notes.map((note, index) => (
                                <div className="col-md-4" key={index}>
                                    <div className="card mb-4 shadow-sm">
                                        <div className="card-body">
                                            <p className="card-text">{note.content}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div> : null
                }
            </div>
        );
    }
}
