import {Component} from "react";

type Props = {};

type State = {}

export default class Home extends Component<Props, State> {
    render() {
        return (
            <div className="container">
                <header className="jumbotron">
                    <h1>Welcome to Notes!</h1>
                </header>
            </div>
        );
    }
}
