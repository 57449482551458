import axios from 'axios';
import authHeader from "./auth-header";

const API_URL = 'https://api-raven.k4anubhav.com/';

const query = `
query Notes {
    notes {
        id
        content
    }
}`;

class UserService {
    getNotes() {

        return axios.post(API_URL + 'graphql/', {query}, {headers: authHeader()}).then(response => {
            if (response.data.errors) {
                throw new Error(response.data.errors[0].message);
            }
            return response.data.data.notes;
        });
    }
}

export default new UserService();
